import jwtInterceptor from 'interceptors/jwtInterceptor'
import { Permission, User } from 'models'
import {
  apiGetUser,
  apiLoginUser,
  apiLogoutUser,
  apiPermissions,
  apiRecoveryPass,
  loadAbort,
} from 'utilities'

export const getToken = () => {
  return window.localStorage.getItem('key')
}

export const getSessionToken = () => {
  return window.sessionStorage.getItem('key')
}

export const setToken = (token: string) => {
  return window.localStorage.setItem('key', token)
}

export const setSessionToken = (token: string) => {
  return window.sessionStorage.setItem('key', token)
}

export const postLogin = (data: object) => {
  const controller = loadAbort()
  return {
    call: jwtInterceptor.post<User>(apiLoginUser, data, {
      signal: controller.signal,
      withCredentials: true,
    }),
    controller,
  }
}

export const postLogout = () => {
  const controller = loadAbort()
  return {
    call: jwtInterceptor.post(
      apiLogoutUser,
      {},
      { signal: controller.signal, withCredentials: true }
    ),
    controller,
  }
}

export const getUser = () => {
  const controller = loadAbort()
  return {
    call: jwtInterceptor.get<User>(apiGetUser, { signal: controller.signal }),
    controller,
  }
}

export const getUserPermissions = (page: number, limit: number) => {
  const controller = loadAbort()
  return {
    call: jwtInterceptor.get(
      apiPermissions + `?offset=${page * limit}&limit=${limit}`,
      {
        signal: controller.signal,
      }
    ),
    controller,
  }
}

export const addUserPermissions = (payload: object) => {
  const controller = loadAbort()
  return {
    call: jwtInterceptor.post<Permission>(apiPermissions, payload, {
      signal: controller.signal,
    }),
    controller,
  }
}

export const modifyUserPermission = (payload: object, uuid: string) => {
  const controller = loadAbort()
  return {
    call: jwtInterceptor.put<Permission>(apiPermissions + uuid, payload, {
      signal: controller.signal,
    }),
    controller,
  }
}

export const recoveryPassword = (email: string) => {
  const controller = loadAbort()
  return {
    call: jwtInterceptor.post(
      apiRecoveryPass,
      { email },
      { signal: controller.signal }
    ),
    controller,
  }
}
